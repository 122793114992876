import * as React from "react";
import discountIcon from "../images/discount_icon.png";
import upsellIcon from "../images/upsell_icon.png";
import Layout from "../components/layout";
import Seo from "../components/seo";
import StackedCardsLeft from "../components/stackedCardsLeft";
import { Link } from "gatsby";

const SupportCenter = ({ data, location }) => {
  const siteTitle = data?.site?.siteMetadata?.title || `OneTeam.ai support center`;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Help Content" />
      <div className="blog mx-auto flex flex-wrap flex-col items-center lg:px-40 mb-20">
        <h1 className="my-4 text-2xl font-medium leading-tight">Support Center</h1>
        <StackedCardsLeft>
          <HelpIndexCard />
        </StackedCardsLeft>
      </div>
    </Layout>
  );
};

const CardItem = ({ icon, alt, href, name }) => {
  return (
    <div className="flex flex-col sm:flex-row">
      <div className="w-full sm:w-5/12 p-8">
        <Link to={`/${href}`}>
          <img src={icon} alt={alt} />
        </Link>
      </div>
      <div className="w-full md:w-5/6 sm:w-5/12 flex items-start justify-center sm:justify-start sm:items-center">
        <h3 className="text-xl text-gray-800 font-medium leading-none underline">
          <Link to={`/${href}`}>{name}</Link>
        </h3>
      </div>
    </div>
  );
};

const HelpIndexCard = () => {
  return (
    <div className="w-full sm:max-w-xl">
      <CardItem icon={discountIcon} alt={"discount mixer"} href={"discount-mixer-help"} name={"Discount Mixer"} />
      <CardItem icon={upsellIcon} alt={"upsold"} href={"upsold-help"} name={"Upsold"} />
    </div>
  );
};

export default SupportCenter;
